<template>
  <section>
    <b-row>
      <b-col lg="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>基金规模(BTC) </b-card-title>
          </b-card-header>
          <b-card-body>
            <inventory ref="fundScale" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { getProshareFundData } from "@/api/BtcEtf.js";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import Inventory from "@/views/charts-and-maps/charts/echart/option-echart/Inventory.vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    Inventory,
  },
  data() {
    return {
      tempData: [], //存放Proshare价格信息所有数据
      ProshareData: [],
      FundTimeData: [],
      YCharData: [],
    };
  },
  created() {
    this.getFundList();
  },
  mounted() {},
  methods: {
    //请求Proshare图表数据
    getFundList() {
      getProshareFundData()
        .then((res) => {
          let tempData = [...res.data.data];
          // this.ProshareData = tempData[0];
          // console.log(1111, this.ProshareData);

          let set = new Set();
          for (let i = 0; i < tempData.length; i++) {
            let day =
              new Date(tempData[i].datetime).getDate() < 10
                ? "0" + new Date(tempData[i].datetime).getDate()
                : new Date(tempData[i].datetime).getDate();
            set.add(new Date(tempData[i].datetime).getMonth() + 1 + "-" + day);
          }

          this.FundTimeData = Array.from(set);
          // console.log("123",this.FundTimeData)

          this.YCharData = tempData.map((v) => parseFloat(v.value).toFixed(2));
          // console.log("123",YCharData)

          this.fundScaleData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取基金规模图表上数据
    fundScaleData(type) {
      if (type === 1) {
        this.$refs.fundScale.chart.setOption({
          legend: {
            textStyle: {
              color: "#ffff",
            },
          },
          xAxis: {
            data: this.FundTimeData.reverse(),
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            formatter: (params) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

              params.forEach((item) => {
                var aaa =  this.kmb(item.value);
                result += `<span style="color:#EACCAE">${item.seriesName} :${aaa}</span></br>`;
              });
              return result;
            },
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          yAxis: {
            type: "value",
            nameTextStyle: {
              width: 100,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            axisLabel: {
            formatter: function (v) {
              if (v > 1000 && v <= 1000000) {
                return v / 1000 + "K";
              } else if (v > 1000000 && v <= 1000000000) {
                return v / 1000000 + "M";
              } else if (v > 1000000000) {
                return v / 1000000000 + "B";
              } else {
                return v;
              }
              // return v + 'K';
            },
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
            margin: 20,
          },
          },
          dataZoom: [
            {
              type: "inside",
              start: 50,
              end: 100,
            },
            {
              start: 50,
              end: 100,
            },
          ],
          grid: {
            left: 90,
          },
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image:
                  "https://apilib.rta.academy/media/2023/07/26/echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
          series: [
            {
              name: "资产净值",
              data: this.YCharData.reverse(),
              type: "bar",
              itemStyle: {
                color: "#EFC394",
              },
            },
          ],
        });
      }
    },
  },
};
</script>
<style lang="scss">
</style>